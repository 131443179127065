import {Component, OnInit} from '@angular/core';
import {AppService, RoleService, UserAuthService} from "projects/common/src/lib/service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    constructor(
        private authService: UserAuthService,
        private appService: AppService,
        private roleService: RoleService) {
    }
    ngOnInit() {
        this.getSecurityGroups();
        this.getColumnConfig();
    }

    private getColumnConfig() {
        this.appService.datatableSetup('LMS');
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('LMS');
    }
}
