import { Component, OnInit } from "@angular/core";
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Router } from "@angular/router";

@Component({
    template: "",
})
export class ValidateComponent implements OnInit {

    constructor(private authService: UserAuthService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService,
        private userService: UserAuthService) { }

    ngOnInit() {
        this.getToken();
        this.getSecurityGroups();
        this.getColumnConfig();
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            if(urlParams.get('appName') === 'SmartParent') {
                window.sessionStorage.setItem('studentProfileId', urlParams.get('studentProfileId'));
                window.sessionStorage.setItem('studentName', urlParams.get('studentName'));
            } else {
                this.userService.saveEmployeeName(urlParams.get('employeeName'));
                this.userService.saveEmployeeId(urlParams.get('employeeId'));
            }
            window.sessionStorage.setItem('schoolName', urlParams.get('schoolName'));
            window.sessionStorage.setItem('appName', urlParams.get('appName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            this.userService.saveSchoolName(urlParams.get('schoolName'));



            setTimeout(() => {
                this.router.navigateByUrl('/', { preserveQueryParams: false });
            }, 900);
        }
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('LMS');
      }

    private getColumnConfig() {
        this.appService.datatableSetup('LMS');
    }
}
