import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {AppCommonModule, AppConfigModule, AuthInterceptor, ResponseInterceptor} from "../../../common/src/lib";
import {APIConstant, CoreModule, PublicAPI} from "@finance-core";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ValidateComponent} from "./validate/validate.component";
import { NguCarouselModule } from '@ngu/carousel';


@NgModule({
    declarations: [
        AppComponent,
        ValidateComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SweetAlert2Module.forRoot(),
        AppCommonModule,
        CoreModule,
        AppConfigModule.forRoot({
            publicAPIs: PublicAPI,
            apiPath: APIConstant.basePath
        }),
        NguCarouselModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
